<template>
  <div class="px-5 sms-message-view">
    <v-container class="mt-4">
      <v-text style="font-size: 24px; font-weight: 800"
        >Direct Debit SMS Messages</v-text
      >
      <v-row class="mt-6">
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="2" class="mt-3" padding="5px 12px">
              <v-text style="color: black; font-size: 16px"
                >DD Message 1</v-text
              >
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                dense
                solo
                placeholder="Message"
                :rules="[rules.required, rules.counter]"
                v-model="dd_1"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="2" class="mt-3">
              <v-text style="color: black; font-size: 16px"
                >DD Message 2</v-text
              >
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                dense
                solo
                placeholder="Message"
                v-model="dd_2"
                :rules="[rules.required, rules.counter]"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="2" class="mt-3">
              <v-text style="color: black; font-size: 16px"
                >DD Message 3</v-text
              >
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                dense
                solo
                placeholder="Message"
                v-model="dd_3"
                :rules="[rules.required, rules.counter]"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="2" class="mt-3">
              <v-text style="color: black; font-size: 16px"
                >DD Message 4</v-text
              >
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                dense
                solo
                placeholder="Message"
                v-model="dd_4"
                :rules="[rules.required, rules.counter]"
              />
              <!-- <v-text
                style="color: #f0971f"
                v-if="dd_4.length > 150 && dd_4.length < 160"
                >You entered more than 150 characters. The maximum character
                length is 160.</v-text
              > -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-4">
      <v-text style="font-size: 24px; font-weight: 800"
        >Apparent Leavers SMS Messages</v-text
      >
      <v-row class="mt-6">
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="2" class="mt-3">
              <v-text style="color: black; font-size: 16px"
                >AL Message 1</v-text
              >
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                dense
                solo
                placeholder="Message"
                :rules="[rules.required, rules.counter]"
                v-model="al_1"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="2" class="mt-3">
              <v-text style="color: black; font-size: 16px"
                >AL Message 2</v-text
              >
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                dense
                solo
                placeholder="Message"
                v-model="al_2"
                :rules="[rules.required, rules.counter]"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="2" class="mt-3">
              <v-text style="color: black; font-size: 16px"
                >AL Message 3</v-text
              >
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                dense
                solo
                placeholder="Message"
                v-model="al_3"
                :rules="[rules.required, rules.counter]"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="2" class="mt-3">
              <v-text style="color: black; font-size: 16px"
                >AL Message 4</v-text
              >
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                dense
                solo
                placeholder="Message"
                v-model="al_4"
                :rules="[rules.required, rules.counter]"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="d-flex text-center justify-center" @click="update">
      <v-btn class="primary mt-4" :loading="isLoading">Save</v-btn>
    </div>
    <v-snackbar
      :timeout="3000"
      :value="notification"
      absolute
      right
      top
      tile
      :color="notificationColor"
    >
      {{ notificationTxt }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { APIdoUpdateSMSMessagesSetting } from "@/api/common/api";

export default {
  props: {
    region: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      notification: false,
      notificationTxt: "",
      notificationColor: "",
      isLoading: false,
      al_1: "",
      al_2: "",
      al_3: "",
      al_4: "",
      al_4: "",
      dd_1: "",
      dd_2: "",
      dd_3: "",
      dd_4: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length < 160 || "Max 160 characters",
      },
    };
  },
  mounted() {
    this.dd_1 = this.messagesValue["text_dd1_" + this.region];
    this.dd_2 = this.messagesValue["text_dd2_" + this.region];
    this.dd_3 = this.messagesValue["text_dd3_" + this.region];
    this.dd_4 = this.messagesValue["text_dd4_" + this.region];
    this.al_1 = this.messagesValue["text_al1_" + this.region];
    this.al_2 = this.messagesValue["text_al2_" + this.region];
    this.al_3 = this.messagesValue["text_al3_" + this.region];
    this.al_4 = this.messagesValue["text_al4_" + this.region];
  },
  computed: {
    ...mapGetters({
      settingValue: "getSettingsValue",
      messagesValue: "getMessagesValue",
    }),
  },

  methods: {
    ...mapActions({
      doGetMessages: "doGetMessages",
    }),
    update() {
      const postData = {};
      postData["text_dd1"] = this.dd_1;
      postData["text_dd2"] = this.dd_2;
      postData["text_dd3"] = this.dd_3;
      postData["text_dd4"] = this.dd_4;
      postData["text_al1"] = this.al_1;
      postData["text_al2"] = this.al_2;
      postData["text_al3"] = this.al_3;
      postData["text_al4"] = this.al_4;
      this.isLoading = true;

      APIdoUpdateSMSMessagesSetting(postData, this.region)
        .then((res) => {
          this.notification = true;
          this.notificationTxt = "Successfully Updated";
          this.notificationColor = "success";
          this.doGetMessages();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
    },
  },
};
</script>
<style lang="scss">
.sms-message-view {
  .col-sm-2,
  .col-12 {
    padding: 5px 12px;
  }
  .error--text {
    .v-text-field__details {
      display: block;
    }
  }
}
</style>
