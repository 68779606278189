<template>
  <v-container id="regular-tables" fluid tag="section" class="pt-10">
    <v-tabs v-model="tabs" fixed-tabs>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#mobile-tabs-5-1" class="primary--text">
        <v-text>London</v-text>
      </v-tab>

      <v-tab href="#mobile-tabs-5-2" class="primary--text">
        <v-text>Scotland</v-text>
      </v-tab>

      <v-tab href="#mobile-tabs-5-3" class="primary--text">
        <v-text>Midlands</v-text>
      </v-tab>
      <v-tab href="#mobile-tabs-5-4" class="primary--text">
        <v-text>Southern</v-text>
      </v-tab>
      <v-tab href="#mobile-tabs-5-5" class="primary--text">
        <v-text>Wales & South West</v-text>
      </v-tab>
      <v-tab href="#mobile-tabs-5-6" class="primary--text">
        <v-text>Northern</v-text>
      </v-tab>
      <v-tab href="#mobile-tabs-5-7" class="primary--text">
        <v-text>North West</v-text>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item value="mobile-tabs-5-1">
        <EmailMessageTabView region="lo" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-2">
        <EmailMessageTabView region="sc" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-3">
        <EmailMessageTabView region="mi" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-4">
        <EmailMessageTabView region="so" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-5">
        <EmailMessageTabView region="sw" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-6">
        <EmailMessageTabView region="no" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-7">
        <EmailMessageTabView region="nw" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import EmailMessageTabView from "../component/Settings/EmailMessageTabView.vue";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tabs: null,
    };
  },
  components: {
    EmailMessageTabView,
  },
  mounted() {
    if (this.getUserDetails.user_type !== "admin") this.$router.push("/");
  },
  computed: {
    ...mapGetters({
      getUserDetails: "getUserData",
    }),
  },
};
</script>
